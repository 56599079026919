
import type { PropType } from 'vue'
import { defineComponent, ref, watch } from 'vue'
import dateTimeService from '@/services/dateTimeService'

import TmFormLine from '@/components/shared/TmFormLine.vue'
import FieldText from '@/components/shared/field/FieldText.vue'
import FieldTimepicker from '@/components/shared/field/FieldTimepicker.vue'

import { handleDateFormatSymbol } from '@/services/utils'
import { shortDateFormat, defaultTimeFormat } from '@/definitions/_general/_data/general'

import type { DropdownDueDateBaseSize } from '@/components/shared/dropdowns/TmDropdownDueDateBase.vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  components: {
    TmButton,
    TmFormLine,
    FieldText,
    FieldTimepicker,
  },
  inheritAttrs: false,
  props: {
    modelValue: {
      type: Date,
      required: true,
    },
    showClearButton: {
      type: Boolean,
    },
    size: {
      type: String as PropType<DropdownDueDateBaseSize>,
      default: '',
    },
  },
  emits: [
    'update:modelValue',
    'update-menu-position',
    'cancel',
  ],
  setup(props, context) {
    const inputDateField = ref()

    const inputDateFormat = shortDateFormat
    const calendarDateFormat = 'YYYY/MM/DD'
    const inputTimeFormat = defaultTimeFormat

    const inputDateValue = ref('')
    const dateValue = ref('')
    const lastCorrectDateString = ref('')
    const inputTimeValue = ref('')

    // save date/time/reminder
    const saveAll = () => {
      const dateTimeValue = inputDateValue.value + ' ' + inputTimeValue.value
      const dateTimeFormat = inputDateFormat + ' ' + inputTimeFormat
      context.emit('update:modelValue', dateTimeService.strToDate(dateTimeValue, dateTimeFormat))
    }

    // on date changed from calendar
    const onDateChanged = (date: string) => {
      dateValue.value = date
      const dateInDateFormat = dateTimeService.strToDate(date, calendarDateFormat)
      // update input in menu
      inputDateValue.value = dateTimeService.format(dateInDateFormat, inputDateFormat)
      lastCorrectDateString.value = inputDateValue.value
      saveAll()
    }

    const onManualDateInput = (value: string) => {
      inputDateValue.value = value
      if (dateTimeService.isInFormat(value, inputDateFormat)) {
        const newDate = dateTimeService.strToDate(value, inputDateFormat)
        dateValue.value = dateTimeService.format(newDate, calendarDateFormat)
        lastCorrectDateString.value = value
      }
    }

    const setLastCorrectDate = () => {
      inputDateValue.value = lastCorrectDateString.value
    }

    const setValue = (value: Date) => {
      inputDateValue.value = dateTimeService.format(value, inputDateFormat)
      dateValue.value = dateTimeService.format(value, calendarDateFormat)
      lastCorrectDateString.value = inputDateValue.value
      inputTimeValue.value = dateTimeService.format(value, inputTimeFormat)
    }

    watch(
      () => props.modelValue,
      (newValue) => setValue(newValue),
      { immediate: true }
    )

    return {
      inputDateField,
      inputDateFormat,
      inputDateValue,
      inputTimeFormat,
      inputTimeValue,
      dateValue,
      onDateChanged,
      saveAll,
      onManualDateInput,
      calendarDateFormat,
      handleDateFormatSymbol,
      setLastCorrectDate,
    }
  },
})
