
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmModalCloseBtn from '@/components/shared/modal/TmModalCloseBtn.vue'
import TmEditableText from '@/components/shared/TmEditableText.vue'

import TasksDetailsActions from '@/components/pages/tasks/details/TasksDetailsActions.vue'
import TasksDetailsSidebar from '@/components/pages/tasks/details/TasksDetailsSidebar.vue'

import TasksDetailsMain from '@/components/pages/tasks/details/TasksDetailsMain.vue'
import TmTabsNav from '@/components/shared/TmTabsNav.vue'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  components: {
    TmTabsNav,
    TasksDetailsMain,
    TasksDetailsActions,
    TasksDetailsSidebar,
    TmModal,
    TmEditableText,
    TmModalCloseBtn,
  },
  props: {
    emptyView: {
      type: Boolean,
    },
  },
  setup(props) {
    const { isSmMax } = useBreakpoints()
    const showAttachments = ref(!props.emptyView)
    const showChecklist = ref(!props.emptyView)
    const title = ref(props.emptyView ? '' : '[Back] Tickets - Ticket creation - The chat ID link in the internal note notification email is displayed as plain text')
    const tabs = [
      {
        name: 'content',
        label: 'Task content',
      },
      {
        name: 'details',
        label: 'Details',
      },
    ]
    const currentTab = ref(tabs[0].name)

    return {
      currentTab,
      tabs,
      title,
      showAttachments,
      isSmMax,
      showChecklist,
    }
  },
})
