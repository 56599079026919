
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmDropdownDueDateBase from '@/components/shared/dropdowns/TmDropdownDueDateBase.vue'
import { formatDate } from '@/services/dateTimeService'

export default defineComponent({
  components: {
    TmButton,
    TmDropdownDueDateBase,
  },
  setup() {
    const reminderOptions = [
      'None',
      'At time of due date',
      '5 minutes before',
      '10 minutes before',
      '30 minutes before',
      '1 hour before',
      '2 hours before',
      '1 day before',
      '2 days before',
      'Custom',
    ]
    const reminderValue = ref()
    const dueDate = ref({ date: new Date('16 Apr 2024 10:00') })

    return {
      dueDate,
      reminderValue,
      reminderOptions,
      formatDate,
    }
  },
})
