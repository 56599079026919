import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f6a5e220"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tasks-details-modal__header" }
const _hoisted_2 = { class: "tasks-details-modal__header-top" }
const _hoisted_3 = { class: "tasks-details-modal__body" }
const _hoisted_4 = {
  key: 1,
  class: "tasks-details-modal__sidebar"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_editable_text = _resolveComponent("tm-editable-text")!
  const _component_tm_modal_close_btn = _resolveComponent("tm-modal-close-btn")!
  const _component_tasks_details_actions = _resolveComponent("tasks-details-actions")!
  const _component_tm_tabs_nav = _resolveComponent("tm-tabs-nav")!
  const _component_tasks_details_main = _resolveComponent("tasks-details-main")!
  const _component_tasks_details_sidebar = _resolveComponent("tasks-details-sidebar")!
  const _component_tm_modal = _resolveComponent("tm-modal")!

  return (_openBlock(), _createBlock(_component_tm_modal, {
    id: "tasksDetails",
    "exact-size": "800px",
    "no-footer": "",
    "no-header": "",
    "without-content-padding": ""
  }, {
    "modal-content": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: _normalizeClass(["headline-18 font-weight-semi-bold lh-22 flex-grow-1", {
              'light--text': !_ctx.title,
            }])
          }, [
            _createVNode(_component_tm_editable_text, {
              modelValue: _ctx.title,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.title) = $event)),
              placeholder: "Type your title"
            }, null, 8, ["modelValue"])
          ], 2),
          _createVNode(_component_tm_modal_close_btn, {
            "icon-size": "xLarge",
            class: "ml-2 mt-n2px"
          })
        ]),
        _createVNode(_component_tasks_details_actions, {
          onShowAttachments: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showAttachments = true)),
          onShowCheckList: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showChecklist = true))
        }),
        _createVNode(_component_tm_tabs_nav, {
          modelValue: _ctx.currentTab,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.currentTab) = $event)),
          class: "lt-md px-6 mt-8",
          tabs: _ctx.tabs
        }, null, 8, ["modelValue", "tabs"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        (!_ctx.isSmMax || _ctx.currentTab === 'content')
          ? (_openBlock(), _createBlock(_component_tasks_details_main, {
              key: 0,
              "show-attachments": _ctx.showAttachments,
              "show-checklist": _ctx.showChecklist,
              "empty-view": _ctx.emptyView
            }, null, 8, ["show-attachments", "show-checklist", "empty-view"]))
          : _createCommentVNode("", true),
        (!_ctx.isSmMax || _ctx.currentTab === 'details')
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_tasks_details_sidebar, { "empty-view": _ctx.emptyView }, null, 8, ["empty-view"])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}