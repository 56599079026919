import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a355bdb8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "kanban-details-block" }
const _hoisted_2 = { class: "kanban-details-block__header" }
const _hoisted_3 = {
  key: 0,
  class: "kanban-details-block__icon"
}
const _hoisted_4 = { class: "d-flex align-center" }
const _hoisted_5 = {
  key: 0,
  class: "kanban-details-block__facet"
}
const _hoisted_6 = { class: "ml-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.hideIcon)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_tm_icon, {
              name: _ctx.icon,
              size: "xLarge",
              class: "neutral--text"
            }, null, 8, ["name"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "title", {}, () => [
          _createElementVNode("span", null, _toDisplayString(_ctx.title), 1)
        ], true),
        (_ctx.facet)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.facet), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _renderSlot(_ctx.$slots, "header-right", {}, undefined, true)
      ])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.contentClass, {'pl-9': !_ctx.noPadding && !_ctx.hideIcon}])
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2)
  ]))
}