import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-825f5348"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tasks-details-main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tasks_details_description = _resolveComponent("tasks-details-description")!
  const _component_tasks_details_date = _resolveComponent("tasks-details-date")!
  const _component_kanban_details_checklist = _resolveComponent("kanban-details-checklist")!
  const _component_tasks_details_attachments = _resolveComponent("tasks-details-attachments")!
  const _component_kanban_details_comments = _resolveComponent("kanban-details-comments")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_tasks_details_description, { description: _ctx.description }, null, 8, ["description"]),
    _createVNode(_component_tasks_details_date),
    (_ctx.showChecklist)
      ? (_openBlock(), _createBlock(_component_kanban_details_checklist, {
          key: 0,
          checklist: _ctx.checklist,
          "onUpdate:checklist": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checklist) = $event)),
          editable: "",
          onAddItem: _ctx.addCheckListItem
        }, null, 8, ["checklist", "onAddItem"]))
      : _createCommentVNode("", true),
    (_ctx.showAttachments)
      ? (_openBlock(), _createBlock(_component_tasks_details_attachments, {
          key: 1,
          attachments: _ctx.attachments
        }, null, 8, ["attachments"]))
      : _createCommentVNode("", true),
    _createVNode(_component_kanban_details_comments, {
      comments: _ctx.emptyView ? [] : _ctx.comments
    }, null, 8, ["comments"])
  ]))
}