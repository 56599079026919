
import { defineComponent, ref } from 'vue'
import TmFieldsContainer from '@/components/shared/tmFieldsContainer/TmFieldsContainer.vue'
import TmSystemInfo from '@/components/shared/TmSystemInfo.vue'
import { priorityVariant } from '@/definitions/_general/_data/priorityVariant'
import { assigneeOptions } from '@/definitions/_general/_data/optionsList'

export default defineComponent({
  components: {
    TmFieldsContainer,
    TmSystemInfo,
  },
  props: {
    emptyView: {
      type: Boolean,
    },
  },
  setup(props) {
    const priorityOptions = ref(priorityVariant)
    const priorityValue = ref(priorityOptions.value[0])

    const stageOptions = ref([
      {
        name: 'open',
        color: 'orange',
        outline: true,
      },
      {
        name: 'need attention',
        color: 'red',
        outline: true,
      },
      {
        name: 'in progress',
        color: 'blue',
        outline: true,
      },
      {
        name: 'solved',
        color: 'green',
        outline: true,
      },
    ])
    const stageValue = ref(stageOptions.value[0])

    const styleProps = {
      leftLabel: false,
      inputContainerClass: 'mx-n2',
    }

    const systemInfo = !props.emptyView
      ? [
          {
            label: 'ID',
            text: '3225343',
          },
          {
            label: 'Created',
            text: '11 Jul 2021, 3:14 am',
          },
          {
            label: 'Created by',
            text: 'James Smith',
          },
          {
            label: 'Updated',
            text: 'Yesterday, 11:33 pm',
          },
          {
            label: 'Updated by',
            text: 'Robert Overrit',
          },
        ]
      : [
          {
            label: 'ID',
            text: '3225343',
          },
          {
            label: 'Created',
            text: '11 Jul 2021, 3:14 am',
          },
          {
            label: 'Created by',
            text: 'James Smith',
          },
        ]

    const assigneeValue = ref<any>(props.emptyView ? { fullName: 'Unassigned' } : assigneeOptions[2])

    const typeOptions = ref([
      {
        icon: 'people',
        text: 'Meeting',
      },
      {
        icon: 'tmi-assignment-list',
        text: 'To-do',
      },
      {
        icon: 'call',
        text: 'Call',
      },
    ])
    const typeValue = ref(typeOptions.value[0])

    return {
      typeValue,
      typeOptions,
      systemInfo,
      styleProps,
      priorityOptions,
      priorityValue,
      stageOptions,
      stageValue,
      assigneeOptions,
      assigneeValue,
    }
  },
})
