import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "py-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_fields_container = _resolveComponent("tm-fields-container")!
  const _component_tm_system_info = _resolveComponent("tm-system-info")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_tm_fields_container, _mergeProps({
      modelValue: _ctx.stageValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.stageValue) = $event)),
      label: "Stage",
      type: "selectStatus",
      options: _ctx.stageOptions
    }, _ctx.styleProps), null, 16, ["modelValue", "options"]),
    _createVNode(_component_tm_fields_container, _mergeProps({
      modelValue: _ctx.priorityValue,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.priorityValue) = $event)),
      label: "Priority",
      type: "selectPriority",
      options: _ctx.priorityOptions
    }, _ctx.styleProps), null, 16, ["modelValue", "options"]),
    _createVNode(_component_tm_fields_container, _mergeProps({
      modelValue: _ctx.assigneeValue,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.assigneeValue) = $event)),
      label: "Assignee",
      type: "assignee",
      options: _ctx.assigneeOptions,
      size: "small",
      "avatar-size": "xxSmall",
      placeholder: "Assign to"
    }, _ctx.styleProps), null, 16, ["modelValue", "options"]),
    _createVNode(_component_tm_fields_container, _normalizeProps(_guardReactiveProps(_ctx.styleProps)), {
      default: _withCtx(() => [
        _createVNode(_component_tm_system_info, {
          items: _ctx.systemInfo,
          class: "px-2"
        }, null, 8, ["items"])
      ]),
      _: 1
    }, 16)
  ]))
}