
import { defineComponent, ref } from 'vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'
import KanbanDetailsComment from '@/components/pages/shared/kanban/details/KanbanDetailsComment.vue'
import type { TaskCommentType } from '@/definitions/tasks/types'
import type { PropType } from 'vue'
import type { EditorButtonType } from '@/definitions/shared/editor/types'
import { editorBtns } from '@/definitions/shared/editor/data'

export default defineComponent({
  components: {
    KanbanDetailsComment,
    TmAvatar,
  },
  props: {
    comments: {
      type: Array as PropType<TaskCommentType[]>,
      default: () => [],
    },
  },
  setup() {
    const comment = ref()
    const showEditor = ref(false)

    const btnsList: EditorButtonType[] = [
      editorBtns.text,
      editorBtns.emoji,
      editorBtns.attachment,
      editorBtns.ai,
    ]

    return {
      showEditor,
      comment,
      btnsList,
    }
  },
})
