
import { defineComponent, ref } from 'vue'
import TasksDetailsDescription from '@/components/pages/tasks/details/TasksDetailsDescription.vue'
import TasksDetailsDate from '@/components/pages/tasks/details/TasksDetailsDate.vue'
import TasksDetailsAttachments from '@/components/pages/tasks/details/TasksDetailsAttachments.vue'
import type { TaskCommentType } from '@/definitions/tasks/types'
import KanbanDetailsChecklist from '@/components/pages/shared/kanban/details/KanbanDetailsChecklist.vue'
import KanbanDetailsComments from '@/components/pages/shared/kanban/details/KanbanDetailsComments.vue'
import type { KanbanCheckListItem, KanbanAttachmentType } from '@/definitions/shared/kanban/types'

export default defineComponent({
  components: {
    KanbanDetailsComments,
    KanbanDetailsChecklist,
    TasksDetailsAttachments,
    TasksDetailsDescription,
    TasksDetailsDate,
  },
  props: {
    showChecklist: {
      type: Boolean,
    },
    showAttachments: {
      type: Boolean,
    },
    emptyView: {
      type: Boolean,
    },
  },
  setup(props) {
    const description = ref(props.emptyView ? '' : 'Let’s take a quick look at some of the TextMagic features that will help you to improve your business communication.\n\nThe TextMagic Web app allows you to send and receive text messages very easily. This is especially good for marketing campaigns, because you can make your text messages more personal by using mail merge tags and templates, and you can attach files to your messages. Also, you can schedule messages to be sent at specific times, ensuring that your audience receives them at the most optimal moments.\n\nAnother feature worth mentioning is the two-way messaging capability. This allows your recipients to reply to your messages, fostering better engagement and opening up a channel for feedback.\n\nLet’s take a quick look at some of the TextMagic features that will help you to improve your business communication.\n\nThe TextMagic Web app allows you to send and receive text messages very easily. This is especially good for marketing campaigns, because you can make your text messages more personal by using mail merge tags and templates, and you can attach files to your messages. Also, you can schedule messages to be sent at specific times, ensuring that your audience receives them at the most optimal moments.\n\nAnother feature worth mentioning is the two-way messaging capability. This allows your recipients to reply to your messages, fostering better engagement and opening up a channel for feedback.')
    const checklist = ref<KanbanCheckListItem[]>([])

    const addCheckListItem = () => {
      checklist.value.push({
        title: '',
        isEditable: true,
        checked: false,
        dropdownActive: false,
      })
    }
    const attachments = ref<KanbanAttachmentType[]>([
      {
        title: 'Screenshot_12.10.22.png',
        date: 'Added 1h ago',
        file: 'image',
        size: '48,5 MB',
        url: require('@/assets/images/introduction/video-preview.png'),
      },
    ])
    const comments: TaskCommentType[] = [
      {
        person: {
          uid: '1',
          firstName: 'Robert',
          lastName: 'Overit',
          fullName: 'Robert Overit',
          avatar: '',
          avatarColor: 'blue',
        },
        text: 'Create ticket after task will be done. When a new incoming message reaches your virtual TextMagic number, you will get notified via a desktop alert and – if needed – via email and mobile app push notifications. ',
        date: '12 Apr 12:28 am',
      },
    ]

    return {
      description,
      checklist,
      addCheckListItem,
      attachments,
      comments,
    }
  },
})
