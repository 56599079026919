
import type { PropType } from 'vue'
import { defineComponent, ref, watch, computed } from 'vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmDropdownDueDateBaseMenu from '@/components/shared/dropdowns/TmDropdownDueDateBaseMenu.vue'
import type { DueDateValue } from '@/definitions/_general/_types/types'
import { getDueDate } from '@/services/dueDate'
export type DropdownDueDateBaseSize = 'small' | ''

export default defineComponent({
  components: {
    TmDropdown,
    TmDropdownDueDateBaseMenu,
  },
  props: {
    modelValue: {
      type: Object as PropType<DueDateValue>,
      default: () => ({
        date: null,
        reminder: '',
      }),
    },
    hideReminder: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String as PropType<DropdownDueDateBaseSize>,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'showMenu', 'hideMenu'],
  setup(props, context) {
    const menuIsVisible = ref(false)
    const menuValue = ref<Date>()
    const reminderValue = ref('')

    const dueDate = ref({ textValue: '', textClass: '', iconName: '' })
    const dueDateComputed = computed(() => ({ ...dueDate.value }))

    const onDateUpdate = (value: Date) => {
      emitValue(value)
    }
    const setValue = (value: Date) => {
      dueDate.value = { ...getDueDate(value) }
      menuValue.value = value || new Date()
    }

    const closeMenu = () => {
      menuIsVisible.value = false
      context.emit('hideMenu')
    }
    const showMenu = () => {
      if (!props.readonly) {
        menuIsVisible.value = true
        context.emit('showMenu')
      }
    }

    const emitValue = (date: Date | null) => {
      context.emit('update:modelValue', {
        date: date,
        reminder: (date && reminderValue.value !== 'None') ? reminderValue.value : '',
      })
    }

    watch(
      () => props.modelValue,
      (newValue) => {
        reminderValue.value = newValue.reminder ? newValue.reminder : 'None'
        setValue(newValue.date)
      },
      { immediate: true }
    )

    return {
      menuIsVisible,
      dueDateComputed,
      onDateUpdate,
      menuValue,
      reminderValue,
      closeMenu,
      showMenu,
      emitValue,
    }
  },
})
