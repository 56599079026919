import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_dropdown_due_date_base = _resolveComponent("tm-dropdown-due-date-base")!
  const _component_tm_auto_size_panel_item_button = _resolveComponent("tm-auto-size-panel-item-button")!
  const _component_tm_auto_size_panel = _resolveComponent("tm-auto-size-panel")!

  return (_openBlock(), _createBlock(_component_tm_auto_size_panel, {
    class: "tasks-details-actions",
    "max-visible-items": 5
  }, {
    default: _withCtx(() => [
      _createVNode(_component_tm_dropdown_due_date_base, {
        ref: "dueDateRef",
        "hide-dropdown-wrapper": "",
        target: _ctx.dateTarget
      }, null, 8, ["target"]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actions, (item, i) => {
        return (_openBlock(), _createBlock(_component_tm_auto_size_panel_item_button, {
          key: i,
          text: item.label,
          icon: item.icon,
          "btn-size": "",
          "icon-size": "medium",
          onClick: e => item.action(e)
        }, null, 8, ["text", "icon", "onClick"]))
      }), 128))
    ]),
    _: 1
  }))
}