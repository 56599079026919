
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TasksDetailsAttachment from '@/components/pages/tasks/details/TasksDetailsAttachment.vue'
import type { PropType } from 'vue'
import type { KanbanAttachmentType } from '@/definitions/shared/kanban/types'

export default defineComponent({
  components: {
    TasksDetailsAttachment,
    TmButton,
  },
  props: {
    attachments: {
      type: Array as PropType<KanbanAttachmentType[]>,
    },
  },
})
