
import { computed, defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmButtonMore from '@/components/shared/TmButtonMore.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'

import type { KanbanCheckListItem } from '@/definitions/shared/kanban/types'
import type { PropType } from 'vue'
import TmProgress from '@/components/shared/TmProgress.vue'
import { rgbToHex, styleVars } from '@/compositions/styleVariables'
import { useModals } from '@/compositions/modals'
import TmEditableText from '@/components/shared/TmEditableText.vue'
import KanbanDetailsBlock from '@/components/pages/shared/kanban/details/KanbanDetailsBlock.vue'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  components: {
    KanbanDetailsBlock,
    TmEditableText,
    TmProgress,
    TmDropdownItem,
    TmButtonMore,
    TmButton,
    TmDropdown,
  },
  props: {
    checklist: {
      type: Array as PropType<KanbanCheckListItem[]>,
      default: () => [],
    },
    icon: {
      type: String,
      default: 'tmi-checkbox-alt',
    },
    checklistTitle: {
      type: String,
      default: 'Checklist',
    },
    checklistItemPlaceholder: {
      type: String,
      default: 'Type your subtask',
    },
    hideDeleteAction: {
      type: Boolean,
    },
    checklistItemName: {
      type: String,
      default: 'item',
    },
    editable: {
      type: Boolean,
    },
  },
  emits: ['add-item', 'update:checklist'],
  setup(props, { emit }) {
    const { isLgMax } = useBreakpoints()
    const { openModal } = useModals()
    const localChecklist = ref(props.checklist)
    const totalLength = computed(() => localChecklist.value.length)
    const checkedLength = computed(() => localChecklist.value.filter((e: KanbanCheckListItem) => e.checked).length)

    const progressValue = computed(() => checkedLength.value ? checkedLength.value / totalLength.value : 0)
    const openDeleteChecklistModal = () => {
      openModal('confirmation', {
        title: 'Delete checklist',
        text: 'Are you sure you want to delete this checklist? This action cannot be undone.',
        btnText: 'Delete',
        btnColor: 'error',
      })
    }

    const openDeleteChecklistItem = () => {
      openModal('confirmation', {
        title: `Delete ${props.checklistItemName}`,
        text: `Are you sure you would like to delete this ${props.checklistItemName}? This action cannot be undone.`,
        btnText: 'Delete',
        btnColor: 'error',
      })
    }

    const title = ref(props.checklistTitle)
    const handleCancel = (item: KanbanCheckListItem) => {
      if (!item.title) {
        localChecklist.value = localChecklist.value.filter((e: KanbanCheckListItem) => e !== item)
        emit('update:checklist', localChecklist.value)
      }

      item.isEditable = false
    }

    return {
      isLgMax,
      checkedLength,
      totalLength,
      title,
      openDeleteChecklistModal,
      openDeleteChecklistItem,
      handleCancel,
      progressValue,
      localChecklist,
      rgbToHex,
      styleVars,
    }
  },
})
