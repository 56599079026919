
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    facet: {
      type: [String, Number],
    },
    icon: {
      type: String,
    },
    title: {
      type: String,
    },
    noPadding: {
      type: Boolean,
    },
    contentClass: {
      type: String,
    },
    hideIcon: {
      type: Boolean,
    },
  },
})
