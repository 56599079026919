import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1bbb607d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex-grow-1" }
const _hoisted_2 = { class: "d-flex justify-between mb-1 align-center" }
const _hoisted_3 = { class: "body-text-semi-bold-14 lh-24" }
const _hoisted_4 = { class: "lh-24 body-text-regular-12 distinct--text hide-on-hover" }
const _hoisted_5 = { class: "body-text-regular-14 lh-20 emphasize--text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_avatar = _resolveComponent("tm-avatar")!
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_tm_dropdown_item = _resolveComponent("tm-dropdown-item")!
  const _component_tm_dropdown = _resolveComponent("tm-dropdown")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["kanban-details-comment", {
      'kanban-details-comment--dropdown-active': _ctx.dropdownActive,
    }])
  }, [
    _createVNode(_component_tm_avatar, {
      url: _ctx.person.avatar,
      color: _ctx.person.avatarColor,
      size: "small",
      class: "mr-3 gt-sm",
      name: _ctx.person.fullName
    }, null, 8, ["url", "color", "name"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.person.fullName), 1),
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.date), 1),
        _createVNode(_component_tm_dropdown, {
          modelValue: _ctx.dropdownActive,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dropdownActive) = $event)),
          class: "show-on-hover"
        }, {
          menu: _withCtx(() => [
            _createVNode(_component_tm_dropdown_item, {
              label: "Delete",
              icon: "delete",
              onClick: _ctx.openDeleteCommentModal
            }, null, 8, ["onClick"])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_tm_button, {
              size: "small",
              "icon-only": "",
              flat: "",
              color: "transparent"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_tm_icon, { name: "more_vert" })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.text), 1)
    ])
  ], 2))
}