
import { defineComponent, onMounted, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import type { EditorButtonType } from '@/definitions/shared/editor/types'
import { editorBtns } from '@/definitions/shared/editor/data'

export default defineComponent({
  components: {
    TmButton,
  },
  props: {
    description: {
      type: String,
    },
  },
  setup(props) {
    const textRef = ref()
    const isLargeText = ref(false)
    const isExpanded = ref(false)
    const showEditor = ref(false)
    const text = ref(props.description ? props.description : '')

    onMounted(() => {
      if (textRef.value.scrollHeight > textRef.value.offsetHeight) {
        isLargeText.value = true
      }
    })

    const btnsList: EditorButtonType[] = [
      editorBtns.text,
      editorBtns.emoji,
      editorBtns.attachment,
      editorBtns.ai,
    ]

    return {
      isExpanded,
      showEditor,
      isLargeText,
      text,
      textRef,
      btnsList,
    }
  },
})
