
import { defineComponent, ref } from 'vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmButton from '@/components/shared/TmButton.vue'
import type { PersonType } from '@/definitions/shared/types'
import type { PropType } from 'vue'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  components: {
    TmAvatar,
    TmDropdown,
    TmDropdownItem,
    TmButton,
  },
  props: {
    person: {
      type: Object as PropType<PersonType>,
    },
    date: {
      type: String,
    },
    text: {
      type: String,
    },
  },
  setup() {
    const { openModal } = useModals()
    const dropdownActive = ref(false)
    const openDeleteCommentModal = () => {
      openModal('confirmation', {
        title: 'Delete comment',
        text: 'Are you sure you want to delete this comment? This action cannot be undone.',
        btnText: 'Delete',
        btnColor: 'error',
      })
    }

    return {
      dropdownActive,
      openDeleteCommentModal,
    }
  },
})
