
import { defineComponent, ref } from 'vue'
import TmAutoSizePanel from '@/components/shared/autoSize/TmAutoSizePanel.vue'
import TmAutoSizePanelItemButton from '@/components/shared/autoSize/panel/TmAutoSizePanelItemButton.vue'
import { useModals } from '@/compositions/modals'
import TmDropdownDueDateBase from '@/components/shared/dropdowns/TmDropdownDueDateBase.vue'

type ActionType = {
  label: string;
  icon: string;
  action?: (event?: PointerEvent) => void;
}

export default defineComponent({
  components: {
    TmDropdownDueDateBase,
    TmAutoSizePanelItemButton,
    TmAutoSizePanel,
  },
  emits: ['show-check-list', 'show-attachments'],
  setup(props, { emit }) {
    const { openModal } = useModals()
    const dateTarget = ref()
    const dueDateRef = ref()

    const openDeleteTaskModal = () => {
      openModal('confirmation', {
        title: 'Delete',
        text: 'Are you sure you want to delete this task? This action cannot be undone.',
        btnText: 'Delete',
        btnColor: 'error',
      })
    }
    const openDueDate = (event?: PointerEvent) => {
      if (!event) { return }

      const targetElement = event.target as HTMLElement
      dateTarget.value = targetElement.closest('.tm-btn')
      dueDateRef.value.showMenu()
    }

    const actions: ActionType[] = [
      {
        icon: 'arrow_forward',
        label: 'Move next stage',
      },
      {
        icon: 'event',
        label: 'Due date',
        action: (event?: PointerEvent) => openDueDate(event),
      },
      {
        icon: 'tmi-checkbox-outline',
        label: 'Add checklist',
        action: () => emit('show-check-list'),
      },
      {
        icon: 'tmi-attachment',
        label: 'Attach',
        action: () => emit('show-attachments'),
      },
      {
        icon: 'link',
        label: 'Copy link',
      },
      {
        icon: 'tmi-duplicate',
        label: 'Duplicate',
      },
      {
        icon: 'tmi-person-add',
        label: 'Follow',
      },
      {
        icon: 'delete',
        label: 'Delete',
        action: () => openDeleteTaskModal(),
      },
    ]

    return {
      dateTarget,
      dueDateRef,
      actions,
    }
  },
})
